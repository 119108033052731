import { IHostProps, useEnvironment } from '@wix/yoshi-flow-editor';
import { useEffect } from 'react';
import {
  DESKTOP_WIDGET_MIN_WIDTH,
  MOBILE_WIDGET_MIN_WIDTH,
} from '../../../../../consts';
import { ServiceListLayoutOptions } from '../../../../../types/types';

export type UseWidgetResize = typeof useWidgetResize;

export const useWidgetResize = ({
  resizeComponent,
  serviceListLayout,
}: {
  serviceListLayout: ServiceListLayoutOptions;
  resizeComponent?: IHostProps['resizeComponent'];
}) => {
  const {
    isMobile,
    isEditor,
    isADI,
    dimensions: { width, height },
  } = useEnvironment();

  useEffect(() => {
    const widgetMinWidth = isMobile
      ? MOBILE_WIDGET_MIN_WIDTH
      : DESKTOP_WIDGET_MIN_WIDTH;

    if (isEditor && !isADI) {
      const shouldResizeWidget =
        typeof width === 'number' && width < widgetMinWidth;

      if (shouldResizeWidget && resizeComponent) {
        resizeComponent({
          width: width < widgetMinWidth ? widgetMinWidth : width,
          height,

          mobileResize: isMobile,
        });
      }
    }
  }, [isEditor, isMobile, resizeComponent, width, height, serviceListLayout]);
};
